import React from 'react';
import Toggle from '@amzn/meridian/toggle';

interface IProps {
  isChatVisible: boolean;
  toggleChatVisible: any;
};

const ToggleChatbot = ({isChatVisible, toggleChatVisible}: IProps) => {
  return (
    <Toggle checked={isChatVisible} onChange={toggleChatVisible}>AI&nbsp;Analyst</Toggle>
  );
};

export default ToggleChatbot;
