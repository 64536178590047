import axios from 'axios';
import { getErrorMessage } from './messages';

const questionTargets = {
  topline: {
    database: 'topline_model_db',
    tableNames: ['topline_unified_forecast_pivoted_totals_vw']
  },
  // TODO: ASIN and SNOP are for future use. They should be used after sniffing the question for SNOP or ASIN terms
  asin: {
    database: 'andes',
    tableNames: []
  },
  snop: {
    database: 'andes',
    tableNames: []
  }
}

export const getLLMAPI = (modelRunId: string) => {
  const askQuestion = async (question: String
    ): Promise<String> => {
      try {
        return ( 
          await axios.post('https://rixa2ly5tph63wsm2ctrgnhvfi0gmpnr.lambda-url.us-east-1.on.aws/', {
            question: question,
            modelRunId: modelRunId,
            ...questionTargets.topline
          })
        ).data.answer
      } catch (err) {
          console.error(err);
          return getErrorMessage();
      }
    };
  return {
    askQuestion
  };
}

export default getLLMAPI;
