import React from 'react';
import {css} from 'emotion';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Table, {TableRow, TableCell} from '@amzn/meridian/table';
import PageLoading from '../../common/components/PageLoading';
import {IPageProps} from '../../common/page/models';
import ToplinePage from '../../common/page/ToplinePage';
import {
  convertDateTicks,
  convertTimestampTicks,
  getLocalizedDateTimeStrings,
  getLocalizedDateTimeString,
  getPeriodEndDate
} from '../../common/utils/dates';
import {formatForecastName, formatForecastDescription} from '../../common/utils/forecast';
import {ForecastRecord} from '../../common/apis/models/forecast';
import modelLinks from './modelLinks';

const mastheadAndColumnSpacing = css`
  height: 100%;
  padding: 0.7% 1.5%;
`;
const modelButtonStyle = css`
  padding: 0;
  height: 20px;
`;

const extractForecastRange = (forecast: ForecastRecord): string => {
  const startDate = convertDateTicks(forecast.periodStart);
  const endDate = getPeriodEndDate(startDate, forecast.period);
  const {date: startDateString} = getLocalizedDateTimeStrings(startDate);
  const {date: endDateString} = getLocalizedDateTimeStrings(endDate);
  return `${startDateString} to ${endDateString}`;
}

const extractTrainingDates = (forecast: ForecastRecord): string => {
  const startDate = forecast.metadata['cutoffStartDate'];
  const endDate = forecast.metadata['cutoffEndDate']
  return (startDate || endDate) ?
    `${startDate || 'unknown'} to ${endDate || 'unknown'}` :
    'unknown';
};

const extractDatasetDate = (forecast: ForecastRecord): string =>
  (forecast.metadata['datasetDate'] || 'unknown');

const extractForecastPeriod = (forecast: ForecastRecord): string =>
  (forecast.metadata['period'] || 'unknown');

const extractModelRunId = (forecast: ForecastRecord): string =>
  (forecast.metadata['modelRunId'] || 'unknown');

const MetadataPage = ({forecast}: IPageProps) => {
  return (
    <Column className={mastheadAndColumnSpacing}>
      {
        Boolean(forecast) ?
        (
          <>
            <Row>
              <Heading level={3}>
                {`${formatForecastName(forecast!)} ${formatForecastDescription(forecast!)}`}
              </Heading>
            </Row>
            <Row>
              <Table showDividers={false} spacing="small" showStripes={false}>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Baseline generated:</Heading></TableCell>
                  <TableCell>{getLocalizedDateTimeString(convertTimestampTicks(forecast!.createdAt))}</TableCell>
                </TableRow>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Last updated by:</Heading></TableCell>
                  <TableCell>{forecast!.updatedBy}</TableCell>
                </TableRow>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Last updated:</Heading></TableCell>
                  <TableCell>{getLocalizedDateTimeString(convertTimestampTicks(forecast!.updatedAt))}</TableCell>
                </TableRow>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Training data dates:</Heading></TableCell>
                  <TableCell>{extractTrainingDates(forecast!)}</TableCell>
                </TableRow>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Forecast range:</Heading></TableCell>
                  <TableCell>{extractForecastRange(forecast!)}</TableCell>
                </TableRow>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Forecast period:</Heading></TableCell>
                  <TableCell>{extractForecastPeriod(forecast!)}</TableCell>
                </TableRow>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Model:</Heading></TableCell>
                  <TableCell><Button className={modelButtonStyle} href={modelLinks[forecast!.model] || '#'} target="_blank" type="link">{forecast!.model}</Button></TableCell>
                </TableRow>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Run date:</Heading></TableCell>
                  <TableCell>{extractDatasetDate(forecast!)}</TableCell>
                </TableRow>
                <TableRow highlightOnHover={false}>
                  <TableCell width={260}><Heading level={4}>Model run ID:</Heading></TableCell>
                  <TableCell>{extractModelRunId(forecast!)}</TableCell>
                </TableRow>
              </Table>
            </Row>
          </>
        ) : <PageLoading />
      }
    </Column>
  );
};

export default (props: IPageProps) => (
  <ToplinePage title="Metadata" {...props}>
    <MetadataPage {...props} />
  </ToplinePage>
);