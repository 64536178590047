import {ForecastRecord} from '../apis/models/forecast';
import {ERROR, IN_PROGRESS, SUCCESS} from '../constants/forecastStatus';
import {BASELINE, CLONED, DISCARDED, FINAL} from '../constants/forecastType';
import {formatPeriodDate} from './dates';

export const formatFriendlyId = (forecast: ForecastRecord): string => {
  return forecast.forecastId.substring(0, 8);
};

export const formatForecastName = (forecast: ForecastRecord): string => {
  if (forecast.businessId && forecast.businessId === 'UFF')
    return `${forecast.country} AFO ${formatPeriodDate(forecast.period, forecast.periodStart)}`;
  return `${forecast.country} ${forecast.businessId} ${formatPeriodDate(forecast.period, forecast.periodStart)}`;
};

export const formatForecastDescription = (forecast: ForecastRecord): string => {
  if (forecast.businessId && forecast.businessId === 'UFF')
    return forecast.description.replace('UFF ', 'AFO ');
  return forecast.description;
};

export const formatForecastStatus = (status: string, type: string): string => {
  if (type === DISCARDED) { return 'Discarded'; }
  switch (status) {
    case IN_PROGRESS:
      return 'Processing';
    case ERROR:
      return 'Error';
    case SUCCESS:
      switch (type) {
        case FINAL:
          return 'Final';
        case BASELINE:
        case CLONED:
        default:
          return 'Open';
      };
    default:
      return 'Unknown';
  };
};

export const extractForecastModelRunId = (forecast: ForecastRecord): string => {
  // If the record has modelRunId inside of metadata, return it.
  if (forecast.metadata.modelRunId) {
    return forecast.metadata.modelRunId;
  }

  // Otherwise, extract model metadata (can be JSON string or object)
  let modelData: any = forecast.metadata.models || {};
  if (typeof modelData === 'string') {
    // Convert JSON string to object
    try {
      modelData = JSON.parse(forecast!.metadata.models || '{}');
    }
    catch (e) {
      console.error('Invalid Forecast Model Metadata String: ' + modelData);
    }
  }

  // If the modelRunId exists inside of model metadata, return it.
  if (modelData.modelRunId) {
    return modelData.modelRunId;
  } else {
    // Otherwise check for modelRunId in model metadata subkey (dynamically named, so scanning all)
    for (const key in modelData) {
      if (modelData[key].modelRunId) {
        return modelData[key].modelRunId;
      }
    }
  }

  return '';
}