import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useForecastVersionContext} from '../../app/hooks';
import ActionPopover from '../../common/components/ActionPopover';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {postCloneForecast} from '../../common/apis/forecasting-api-client';
import {IActionButtonProps} from './models';
import ForecastTitle from './ForecastTitle';

const CloneForecastLinkButton = ({anchorRef, forecast, open, setOpen}: IActionButtonProps) => {
  const history = useHistory();
  const {setForecastVersionIdentifier} = useForecastVersionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const actionCallback = useCallback(() => {
    if (isLoading) { return Promise.resolve(); }
    setIsLoading(true);
    return postCloneForecast({
      businessId: forecast.businessId,
      country: forecast.country,
      forecastId: forecast.forecastId,
      versionId: forecast.versionId,
      description: description,
    })
    .catch((err) => {
      console.log('ERROR CLONING RECORD', err);
      setIsLoading(false);
    })
    .then((newForecast) => {
      setIsLoading(false);
      if (newForecast) {
        setForecastVersionIdentifier(newForecast);
        history.push(`/forecasts/${newForecast.forecastId}/versions/${newForecast.versionId}/summary`);
      }
    });
  }, [description, history, isLoading, forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId, setForecastVersionIdentifier]);

  const disabled = !description;
  return (
    <ActionPopover
      actionButtonText="Proceed"
      actionCallback={actionCallback}
      actionIsLoading={isLoading}
      actionTitle="Clone Forecast"
      anchorRef={anchorRef}
      disabled={disabled || isLoading}
      open={open}
      setOpen={setOpen}
    >
      <ForecastTitle forecast={forecast} />
      <Row>
        <Input
          value={description}
          onChange={setDescription}
          type="text"
          size="large"
          width="100%"
          label="New Forecast Description"
          placeholder="Enter a description for your new forecast"
        />
      </Row>
      {
        !disabled ? (<Row>
          <Text>Are you sure that you want to clone this forecast?</Text>
        </Row>) : null
      }
    </ActionPopover>
  );
};

export default CloneForecastLinkButton;
