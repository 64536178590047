import React, {createRef, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import SideMenu, {SideMenuLink} from '@amzn/meridian/side-menu';

export type navSection = {
  items: navItem[];
  link?: string;
  title: string;
};

export type navItem = {
  getActionButton?: (
    anchorRef: React.RefObject<HTMLSpanElement>,
    open: boolean,
    setOpen: (open: boolean) => void,
  ) => React.ReactElement;
  disabled?: boolean;
  link?: string;
  title: string;
};

// We need at least as many refs as are in the navigation list. These cannot be created dynamically in response to props.
const linkRefs: React.RefObject<HTMLSpanElement>[] = Array(50).fill(null).map(() => createRef<HTMLSpanElement>());

interface IProps {
  sections: navSection[];
};


const findSelectedItemByUrl = (selectedUrl: string, sections: navSection[]) => (
  sections.find(
    (section) => section.items.find(
      (sectionItem) => sectionItem.link === selectedUrl
    )));

let NavBarSide = ({sections}: IProps) => {
  const history = useHistory();
  const location = useLocation();

  const [selectedState, setSelectedState] = useState<string>(location.pathname);
  
  const initialSelectedItem = findSelectedItemByUrl(selectedState, sections);
  const initialOpenState = new Map<string, boolean>();
  initialOpenState.set('Actions', true);
  if (initialSelectedItem) {
    initialOpenState.set(initialSelectedItem.link || initialSelectedItem.title, true);
  }
  const [openState, setOpenState] = useState(initialOpenState);

  useEffect(() => {
    setSelectedState(location.pathname);
    const navItem = findSelectedItemByUrl(location.pathname, sections);
    if (!navItem) { return; }
    initialOpenState.set(navItem.link || navItem.title, true);
  }, [initialOpenState, location.pathname, sections]);

  const linkCount = sections.reduce((count, section) => count + section.items.length, 0);
  const [actionPopoverOpenStates, setActionPopoverOpenStates] = useState(Array(linkCount).fill(false));

  return (
    <SideMenu>
      {sections.map((section) => {
        const sectionKey = (section.link || section.title);
        const sectionItems = section.items.map((item, ix) => {
          const isAction = Boolean(item.getActionButton);
          const linkRef = linkRefs[ix];
          const linkOpen = actionPopoverOpenStates[ix];
          const setLinkOpen = (val: boolean) => {
            const linkStates = [...actionPopoverOpenStates];
            linkStates[ix] = val;
            setActionPopoverOpenStates(linkStates);
          };
          return (
            <SideMenuLink
              disabled={item.disabled === true}
              href={isAction ? undefined : item.link}
              key={isAction ? item.title : item.link}
              open={false}
              selected={!isAction && selectedState === item.link} 
              onClick={isAction ? () => { setLinkOpen(true); } : (href: string) => {
                setSelectedState(href);
                history.push(href);
              }}
            >
              {
                isAction ? (
                  <>
                    <span ref={linkRef}>{item.title}</span>
                    {item.getActionButton!(linkRef, linkOpen, setLinkOpen)}
                  </>
                ) : (item.title)
              }
            </SideMenuLink>
          );
        });
        const hasChildren = Boolean(sectionItems.length);
        return (
          <SideMenuLink
            key={sectionKey}
            href={hasChildren ? undefined : sectionKey}
            selected={!hasChildren && selectedState === sectionKey}
            open={Boolean(openState.get(sectionKey))}
            onClick={(href: string | undefined) => {
              if (!hasChildren && href) {
                history.push(href);
                setSelectedState(href);
              }
              openState.set(sectionKey, hasChildren && !openState.get(sectionKey));
              setOpenState(new Map(openState));
            }}
          >
            {section.title}
            {sectionItems}
          </SideMenuLink>
        );
      })}
    </SideMenu>
  );
};

export default NavBarSide;
