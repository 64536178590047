export enum ForecastOverlayListSortKeys {
  Site = 'site',
  Date = 'date',
  Value = 'value',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
}

export interface ForecastOverlayRecord {
  site: string;
  value: number;
  updatedAt: number;
  updatedBy: string;
};

export interface GetForecastOverlayListResponse {
  forecastId: string;
  versionId: number;
  overlayMetric: string;
  overlays: Array<ForecastOverlayRecord>;
  pageSize: number;
  totalRecords: number;
}
