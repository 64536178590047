import React from 'react';
import {useLocation} from 'react-router-dom';
import {css} from 'emotion';
import Text from '@amzn/meridian/text';
import LogoLink from './links/LogoLink';
import SelectBusinessLink from './links/SelectBusinessLink';
import AdminLink from './links/AdminLink';
import HelpLink from './links/HelpLink';
import DarkToggleLink from './links/DarkToggleLink';
import {useBusinessRegionContext, useThemeContext} from '../../app/hooks';
import {ITheme} from '../../common/styles/themes/models';

const getNavBarStyle = (theme: ITheme) => (css`
  color: ${theme.TextPrimary};
  font-family: 'Amazon Ember', Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  -moz-box-align: stretch;
  align-items: stretch;
  box-shadow: ${theme.TableShadow} 0px 4px 8px 0px;
  background-color: ${theme.Background};
  border-bottom: 1px solid ${theme.RowBorder};
  overflow: hidden;
  min-width: 760px;
  min-height: 36px;
  flex-shrink: 0;
  position: relative;
  z-index: 30;
`);

const divLeft = css`
  margin-right: auto;
  display: flex;
  align-items: center;
  padding-left: 1%;
`;

const divRight = css`
  margin-left: auto;
  padding-right: 1%;
  display: flex;
  align-items: center;
`;

const getTitleStyle = (theme: ITheme) => (css`
  margin-right: 2px !important;
  margin-left: 2px !important;
  color: ${theme.TextPrimary} !important;
`);

interface IProps {
  pageTitle: string | void;
  permissionSet: Set<string>;
}

const NavBar = ({pageTitle, permissionSet}: IProps) => {
  const {pathname} = useLocation();
  const {userSelection} = useBusinessRegionContext();
  const {theme} = useThemeContext();
  const hasUserSelection = Boolean(pathname !== '/select-business' && userSelection);

  return (
    <div className={getNavBarStyle(theme)}>
      <div className={divLeft}>
        <LogoLink theme={theme} canViewDashboard={permissionSet.has('canViewDashboard')} hasUserSelection={hasUserSelection} />
        {hasUserSelection ? <SelectBusinessLink theme={theme} /> : null}
        {
          hasUserSelection && pageTitle ?
          <Text type="h200" transform="uppercase" className={getTitleStyle(theme)}>
            {pageTitle}
          </Text> : null
        }
      </div>

      <div className={divRight}>
        <DarkToggleLink theme={theme} />
        {hasUserSelection && permissionSet.has('canViewAdmin') ? <AdminLink theme={theme} /> : null}
        <HelpLink theme={theme} />
      </div>
    </div>
  );
};

export default NavBar;
