import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {useHistory} from 'react-router-dom';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {useBusinessRegionContext, useForecastVersionContext} from '../../app/hooks';
import ForecastListTable from './components/ForecastListTable';
import {GetForecastListResponse} from '../../common/apis/models/getForecastListResponse';
import {IPageProps} from '../../common/page/models';
import ToplinePage from '../../common/page/ToplinePage';
import {WIDESCREEN_QUERY} from '../../common/utils/layout';

const mastheadAndColumnSpacing = css`
  padding: 0.7% 1.5%;
`;

const DashboardPage = () => {
  const history = useHistory();

  const [forecastList, setForecastList] = useState<GetForecastListResponse | void>();
  const {business, country} = useBusinessRegionContext();
  const {
    forecastId,
    versionId,
    clearForecastVersionIdentifier
  } = useForecastVersionContext();

  const isWidescreen = useMediaQuery(WIDESCREEN_QUERY);

  useEffect(() => {
    if (forecastId || versionId) {
      clearForecastVersionIdentifier();
    }
  }, [clearForecastVersionIdentifier, forecastId, versionId]);

  return (
    <Column className={mastheadAndColumnSpacing}>
      <ForecastListTable
        isWidescreen={isWidescreen}
        history={history}
        forecastList={forecastList}
        setForecastList={setForecastList}
        businessId={business!}
        country={country!}
      />
    </Column>
  );
};

export default (props: IPageProps) => (
  <ToplinePage title="Dashboard" {...props}>
    <DashboardPage />
  </ToplinePage>
);
