import React, {useState} from 'react';
import {ITheme, ThemeName} from '../common/styles/themes/models';
import {getTheme} from '../common/styles/themes';

export interface ThemeContextType {
  theme: ITheme,
  themeName: ThemeName,
  setDarkMode: (isDarkMode: boolean) => void;
};

const defaultTheme = Boolean(window.localStorage.getItem('isDarkMode') === 'true') ? ThemeName.Dark : ThemeName.ExcelsiorFresh;
export const ThemeContext = React.createContext<ThemeContextType>({
  theme: getTheme(defaultTheme),
  themeName: defaultTheme,
  setDarkMode: () => {},
});

const ThemeProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
  const [themeName, setThemeName] = useState(defaultTheme);

  const setDarkMode = (isDarkMode: boolean): void => {
    window.localStorage.setItem('isDarkMode', `${isDarkMode}`);
    setThemeName(isDarkMode ? ThemeName.Dark : ThemeName.ExcelsiorFresh);
  };

  return (
    <ThemeContext.Provider value={{
      theme: getTheme(themeName),
      themeName: themeName,
      setDarkMode: setDarkMode,
    }} >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
