import React, {useEffect, useState} from 'react';
import {useAuthenticationContext, useBusinessRegionContext} from './hooks';
import {AuthTokenPayload, BusinessHierarchyNode} from './AuthenticationProvider';
import PageLoading from '../common/components/PageLoading';

export interface AuthorizationContextType extends AuthTokenPayload {
    isAdministrator: boolean;
    isApprover: boolean;
    isAuthorized: boolean;
    permissionsSet: Set<string>;
};

const defaultState = {
    isAdministrator: false,
    isApprover: false,
    isAuthenticated: false,
    isAuthorized: false,
    permissionsSet: new Set<string>(),
    permissionsTree: [] as BusinessHierarchyNode[],
    user: '',
};

export const AuthorizationContext = React.createContext(defaultState);

const extractPermissionSet = (user: string, permissionsTree: any, businessId: string | null, country: string | null): Set<string> => {
    if (!businessId || !country || !user) { return new Set(); }

    const businessPermissions = permissionsTree.find((node: BusinessHierarchyNode) => node.name === 'businessType' && node.value === businessId!.toLowerCase());
    if (!businessPermissions) { return new Set(); }

    const countryFilter = (node: BusinessHierarchyNode) => node.name === 'country' && node.value === country!.toUpperCase();
    const regionPermissions = businessPermissions.nodes.find(
        (node: BusinessHierarchyNode) => node.name === 'regionId' && node.nodes.find(countryFilter));
    if (!regionPermissions) { return new Set(); }

    const countryPermissions = regionPermissions.nodes.find(countryFilter);
    return new Set(countryPermissions?.permissions || []);
};

const AuthorizationProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [permissionsSet, setPermissionsSet] = useState(new Set<string>());
    const authenticationState = useAuthenticationContext();
    const {user, permissionsTree} = authenticationState;
    const {business, country} = useBusinessRegionContext();

    useEffect(() => {
        setPermissionsSet(extractPermissionSet(user, permissionsTree, business, country));
        setIsAuthorized(true);
    }, [business, country, permissionsTree, user, setIsAuthorized, setPermissionsSet]);


    if (isAuthorized) {
        return <AuthorizationContext.Provider value={{
            ...authenticationState,
            isAuthorized,
            permissionsSet,
            isAdministrator: permissionsSet.has('canViewAdmin'),
            isApprover: permissionsSet.has('canFinalizeForecast'),
        }}>{children}</AuthorizationContext.Provider>;
    }

    return <PageLoading />;
};

export default AuthorizationProvider;
