import omit from 'lodash.omit';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetForecastDownloadResponse} from '../models/getForecastDownloadResponse';
import {handleError} from './handleError';

export interface GetForecastDownloadParams {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
}

export const getForecastDownloadEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: GetForecastDownloadParams
): Promise<GetForecastDownloadResponse | void> => {
  try {
    const {businessId, country, forecastId, versionId} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessId', 'country', 'forecastId', 'versionId']),
    });
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId, versionId},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/download/{versionId}',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
