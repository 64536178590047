import React, {useEffect, useState} from 'react';
import {useSettingsContext} from './hooks';
import {Auth, Logger as AmplifyLogger} from 'aws-amplify';
import {Credentials} from 'aws-sdk/clients/sts';
import config, {SystemAPIs} from '../common/config';
import PageLoading from '../common/components/PageLoading';

export interface BusinessHierarchyNode {
    name: string;
    value: string;
    nodes: BusinessHierarchyNode[];
    permissions?: string[];
}

export interface AuthTokenPayload {
    user: string;
    backendApiCredentials?: Credentials;
    isAuthenticated: boolean;
    permissionsTree: BusinessHierarchyNode[];
}

interface Settings {
    stage: string;
    region: string;
    cognitoAppClientId: string;
    cognitoAuthDomain: string;
    cognitoUserPoolId: string;
    cognitoIdentityPoolId: string;
    backendApiEndpoint: string;
}

const defaultState = {
    isAuthenticated: false,
    user: '',
    permissionsTree: [] as BusinessHierarchyNode[],
};

export const AuthenticationContext = React.createContext(defaultState);

AmplifyLogger.LOG_LEVEL = 'WARN';

const amplifySettings = (settings: Settings) => {
    config.Amplify.Auth.region = settings.region;
    config.Amplify.Auth.userPoolId = settings.cognitoUserPoolId;
    config.Amplify.Auth.oauth.domain = settings.cognitoAuthDomain;
    config.Amplify.Auth.userPoolWebClientId = settings.cognitoAppClientId;
    config.Amplify.Auth.identityPoolId = settings.cognitoIdentityPoolId;

    config.Amplify.API.endpoints.forEach((entry) => {
        if (entry.name === SystemAPIs.ForecastingApi.toString()) {
            entry.endpoint = settings.backendApiEndpoint;
            entry.region = settings.region;
        }
    });
};

export const authTokenPayload = async (): Promise<AuthTokenPayload> => {
    const currentSession = await Auth.currentSession();
    const payload = currentSession.getIdToken().payload;
    const permissionsTree = payload.permissionsTree ? (JSON.parse(payload.permissionsTree) as BusinessHierarchyNode[]) : [];
    return {
        user: payload.email,
        backendApiCredentials: payload.backendCredentials ? (JSON.parse(payload.backendCredentials) as Credentials) : undefined,
        isAuthenticated: true,
        permissionsTree,
    };
};

const AuthenticationProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
    const [state, setState] = useState(defaultState);
    const settingsContext = useSettingsContext();
    const settings = (settingsContext.settings as unknown) as  Settings;

    useEffect(() => {
        const authentication = async () => {
            amplifySettings(settings);
            Auth.configure(config.Amplify);


            try {
                await Auth.currentAuthenticatedUser();
                const tokenPayload = await authTokenPayload();
                setState({
                    isAuthenticated: true,
                    user: tokenPayload.user,
                    permissionsTree: tokenPayload.permissionsTree,
                });
            } catch (error) {
                setState(defaultState);
                console.error(error);
                await Auth.signOut();
                await Auth.federatedSignIn({customProvider: 'Midway'});
            }
        };

        authentication();
    }, [settings, state.isAuthenticated, setState]);


    if (state.isAuthenticated) {
        return <AuthenticationContext.Provider value={state}>{children}</AuthenticationContext.Provider>;
    }

    return <PageLoading />;
};

export default AuthenticationProvider;
