import React, {useCallback, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {ForecastOverlayListSortKeys, GetForecastOverlayListResponse} from '../../common/apis/models/getForecastOverlayListResponse';
import {SiteData} from '../../common/components/SiteFilter';
import PageLoading from '../../common/components/PageLoading';
import {IPageProps} from '../../common/page/models';
import ToplinePage from '../../common/page/ToplinePage';
import {WIDESCREEN_QUERY} from '../../common/utils/layout';
import OverlayHeader from './components/OverlayHeader';
import OverlayListTable, { buildTable } from './components/OverlayListTable';
import {NETWORK_SITE_ID, OverlayMetricLevel} from './models';
import {OPS} from '../../common/constants/forecastMetric';
import {getForecastMetricValues} from '../forecast-summary-page/ForecastSummaryPage';
import {convertDateTicks, getISODate, getPeriodEndDate} from '../../common/utils/dates';
import {getForecastOverlayList} from '../../common/apis/forecasting-api-client';
import {SortDirection} from 'ka-table/enums';
import {extractForecastSites} from '../../common/components/SiteFilter/formatting';

const mastheadAndColumnSpacing = css`
  padding: 0.7% 1.5%;
`;

interface IOverlayPageProps extends IPageProps {
  metricId: string;
  metricLevel: OverlayMetricLevel;
  metricName: string;
  uploadEnabled: boolean;
  addForecastOverlayVersion: (metric: string, versionId: number) => void;
};

const OverlayPage = ({
  addForecastOverlayVersion,
  businessId,
  country,
  forecast,
  forecastId,
  forecastState,
  metricId,
  metricLevel,
  metricName,
  uploadEnabled,
  versionId
}: IOverlayPageProps) => {
  const hasIdentifiers = Boolean(businessId && country && forecastId && versionId && metricId)
  const isNetworkLevel = metricLevel === OverlayMetricLevel.NETWORK_LEVEL;
  const latestForecastVersionId = forecast?.versions?.forecast[0]?.versionId || 0;
  const latestOverlayVersionId = forecast?.versions?.overlay[metricId][0]?.versionId || 0;
  const hasNewerOverlays = Boolean(forecast?.versions && (latestForecastVersionId === versionId && latestOverlayVersionId  > latestForecastVersionId));
  const [useForecastOverlayVersionId, setUseForecastOverlayVersionId] = useState(false);
  const [overlayList, setOverlayList] = useState<GetForecastOverlayListResponse | void>();
  const isWidescreen = useMediaQuery(WIDESCREEN_QUERY);
  const [siteData, setSiteData] = useState<SiteData>({
    siteIdentifiers: new Set<string>(),
    siteNames: new Set<string>(),
    siteMapping: {} as {[siteName: string]: Set<string>},
  });
  const [shouldUpdateOverlayList, setShouldUpdateOverlayList] = useState(true);
  const [siteIdentifiers, setSites] = useState<string[] | undefined>();
  const overlayVersionId = (useForecastOverlayVersionId || !hasNewerOverlays) ? versionId! : (latestOverlayVersionId || versionId!);

  const onOverlayUploadSuccess = useCallback(() => {
    addForecastOverlayVersion(metricId, versionId! + 1);
    setShouldUpdateOverlayList(true);
  }, [addForecastOverlayVersion, metricId, setShouldUpdateOverlayList, versionId]);

  useEffect(() => {
    const loadSitesData = async () => {
      if (!forecast || !forecast.businessId || !forecast.country || !forecast.forecastId || !forecast.versionId) {
        return;
      }

      if (!shouldUpdateOverlayList) { return; }
      
      if (isNetworkLevel) {
        const extractedSites = {
          siteIdentifiers: new Set<string>([NETWORK_SITE_ID]),
          siteNames: new Set<string>([NETWORK_SITE_ID]),
          siteMapping: {[NETWORK_SITE_ID]: new Set<string>([NETWORK_SITE_ID])},
        };
        setSiteData(extractedSites);
        setSites(Array.from(extractedSites.siteIdentifiers));
      } else {
        const forecastStartDate = getISODate(convertDateTicks(forecast!.periodStart));
        const forecastEndDate = getISODate(getPeriodEndDate(convertDateTicks(forecast!.periodStart), forecast!.period));
        // TODO replace with an API to retrieve just Sites
        const forecastValuesReponse = await getForecastMetricValues(forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId, forecastStartDate, forecastEndDate, OPS);
        
        const extractedSites = extractForecastSites(forecastValuesReponse!);
        setSiteData(extractedSites);
        setSites(Array.from(extractedSites.siteIdentifiers));
      }

      const GetForecastOverlayListResponse = await getForecastOverlayList({
        businessId: businessId!,
        country: country!,
        forecastId: forecastId!,
        versionId: overlayVersionId,
        overlayMetric: metricId,
        siteIdentifiers: undefined,
        offset: undefined,
        pageSize: undefined,
        sortKey: isNetworkLevel? ForecastOverlayListSortKeys.Date : ForecastOverlayListSortKeys.Site,
        sortDirection: SortDirection.Ascend,
      });

      setOverlayList(GetForecastOverlayListResponse);
      setShouldUpdateOverlayList(false);
      buildTable(metricName, GetForecastOverlayListResponse, isNetworkLevel);
    };
    loadSitesData();
  }, [
    forecast, 
    businessId,
    country,
    forecastId,
    isNetworkLevel,
    overlayVersionId,
    metricId,
    metricName,
    shouldUpdateOverlayList,
  ]);

  return (
    <Column className={mastheadAndColumnSpacing}>
      {hasIdentifiers ? (
        <>
          <OverlayHeader
            businessId={businessId!}
            country={country!}
            filterValue={siteIdentifiers || [] as string[]}
            forecastId={forecastId!}
            versionId={versionId!}
            forecastState={forecastState!}
            hasNewerOverlays={hasNewerOverlays}
            isNetworkLevel={isNetworkLevel}
            isWidescreen={isWidescreen}
            uploadEnabled={uploadEnabled}
            onOverlayUploadSuccess={onOverlayUploadSuccess}
            overlayMetric={metricId}
            siteData={siteData}
            setFilter={setSites}
            setUseForecastOverlayVersionId={setUseForecastOverlayVersionId}
            useForecastOverlayVersionId={useForecastOverlayVersionId}
          />
          <OverlayListTable
            businessId={businessId!}
            country={country!}
            forecastId={forecastId!}
            isNetworkLevel={isNetworkLevel}
            overlayList={overlayList}
            overlayMetric={metricId}
            overlayMetricTitle={metricName}
            siteIdentifiers={siteIdentifiers}
            versionId={overlayVersionId}
          />
        </>
      ) : (
        <PageLoading />
      )}
    </Column>
  );
};

export default (props: IOverlayPageProps) => (
  <ToplinePage title={`${props.metricName} Overlays`} {...props}>
    <OverlayPage {...props} />
  </ToplinePage>
);
