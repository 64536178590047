import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import Card, {CardHeader} from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import {css} from 'emotion';
import {capitalCase} from 'change-case';
import {useThemeContext} from '../../app/hooks';
import {ForecastValue, GetForecastMetricResponse} from '../../common/apis/models/getForecastResponse';
import ApiChart, {ApiChartRecord} from '../../common/components/ApiChart';
import {IPageProps} from '../../common/page/models';
import ToplinePage from '../../common/page/ToplinePage';
import {WIDESCREEN_QUERY} from '../../common/utils/layout';
import {convertDateTicks, getISODate, getLocalizedDateTimeStrings, getPeriodEndDate} from '../../common/utils/dates';
import {extractForecastModelRunId} from '../../common/utils/forecast';
import DataFilter from './DataFilter';
import {SiteData} from '../../common/components/SiteFilter';
import {extractForecastSites} from '../../common/components/SiteFilter/formatting';
import {
  metricNames,
  AGGREGATE,
  CUSTOMERS,
  NEW,
  OPS,
  ORDERS,
  REPEAT,
  UNITS,
} from '../../common/constants/forecastMetric'
import {getForecastMetric} from '../../common/apis/forecasting-api-client';
import LLMChatBot from '../../common/components/ChatBot/LLMChatBot';
import './ForecastSummaryPage.css';

interface ExtractedData {
  maxDate: string;
  minDate: string;
  values: ApiChartRecord[];
}

const defaultExtractedData = {
  values: [] as ApiChartRecord,
  minDate: '',
  maxDate: '',
} as ExtractedData;

// NOTE: Changing the metric order here will change the order of the cards on the page
const CHART_METRICS = [
  {key: UNITS, title: metricNames[UNITS]},
  {key: ORDERS, title: metricNames[ORDERS]},
  {key: CUSTOMERS, title: metricNames[CUSTOMERS]},
  {key: OPS, title: metricNames[OPS]}
];
const CHART_CARDS = [CHART_METRICS.slice(0, 2), CHART_METRICS.slice(2, 4)];

const DECIMAL_PRECISION = 100;
const CARD_WIDTH = 500;
const CARD_HEIGHT = 400;

const CHART_SERIES = [NEW, REPEAT, AGGREGATE];
const CHART_DEFINITIONS = CHART_CARDS.map(row => row.map(({key, title}) => ({
  key,
  title,
  series: CHART_SERIES.map(metricType => ({key: metricType, title: capitalCase(metricType)})),
})));

const ForecastSummaryPage = ({forecast}: IPageProps) => {
  const isWidescreen = useMediaQuery(WIDESCREEN_QUERY);
  const mastheadAndColumnSpacing = css`
    padding: 0.7% 1.5%;
  `;

  const cardStyle = css`
    width: ${isWidescreen ? 50 : 100}%;
    padding-right: 16px;
    padding-top: 16px;

    .recharts-responsive-container {
      overflow: hidden;
    }
  `;

  const chartContainerStyle = css`
    width: 100%;
    height: ${CARD_HEIGHT}px;
  `;

  const {theme} = useThemeContext();
  const [allSitesList, setAllSitesList] = useState<SiteData>({
    siteIdentifiers: new Set<string>(),
    siteNames: new Set<string>(),
    siteMapping: {} as {[siteName: string]: Set<string>},
  });
  const [siteIdentifiers, setSites] = useState<string[] | undefined>();
  // TODO: For future use - allow user to select which metrics to display on dashboard.
  const [metrics /*, setMetrics*/] = useState(CHART_METRICS.map(cm => cm.key));
  const [forecastMetricsValues, setForecastMetricsValues] = useState({} as {[metricName: string]: GetForecastMetricResponse | undefined});
  const [extractedMetricValues, setExtractedMetricValues] = useState({
    units: defaultExtractedData,
    orders: defaultExtractedData,
    customers: defaultExtractedData,
    ops: defaultExtractedData,
  } as {[metricName: string]: ExtractedData});
  const [dateRange, setDateRange] = useState({minDate: '', maxDate: ''});
  const [showDataLabels, setShowDataLabels] = useState(true);
  const [startDate, setStartDateValue] = useState('');
  const [endDate, setEndDateValue] = useState('');
  const [modelRunId, setModelRunId] = useState('');
  const [isChatVisible, setIsChatVisible] = useState(false);

  useEffect(() => {
    const loadForecastValues = async () => {
      if (!forecast || !forecast.businessId || !forecast.country || !forecast.forecastId || !forecast.versionId || !forecast.metadata) {
        return;
      }
      setModelRunId(extractForecastModelRunId(forecast!));
      const forecastStartDate = getISODate(convertDateTicks(forecast!.periodStart));
      const forecastEndDate = getISODate(getPeriodEndDate(convertDateTicks(forecast!.periodStart), forecast!.period));
      setDateRange({
        minDate: forecastStartDate || '', 
        maxDate: forecastEndDate || '', 
      });

      const forecastUnitsValuesReponse = await getForecastMetricValues(forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId, forecastStartDate, forecastEndDate, UNITS);
      const siteData = extractForecastSites(forecastUnitsValuesReponse!);
      setAllSitesList(siteData);
      setSites(Array.from(siteData.siteIdentifiers));

      const forecastOrdersValuesReponse = await getForecastMetricValues(forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId, forecastStartDate, forecastEndDate, ORDERS);

      const forecastCustomersValuesReponse = await getForecastMetricValues(forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId, forecastStartDate, forecastEndDate, CUSTOMERS);

      const forecastOPSValuesReponse = await getForecastMetricValues(forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId, forecastStartDate, forecastEndDate, OPS);

      setForecastMetricsValues({
        [UNITS]: forecastUnitsValuesReponse!,
        [ORDERS]: forecastOrdersValuesReponse!,
        [CUSTOMERS]: forecastCustomersValuesReponse!,
        [OPS]: forecastOPSValuesReponse!,
      });
    };
    loadForecastValues();
  }, [forecast]);

  useEffect(() => {
    const extractMetricsValues = metrics.reduce((extracted: {[metric: string]: ExtractedData}, metric: string) => {
      extracted[metric] = extractData(forecastMetricsValues[metric.toLowerCase()], metric, startDate, endDate, siteIdentifiers)
      return extracted;
    }, {});

    setExtractedMetricValues(extractMetricsValues);

  }, [forecastMetricsValues, metrics, startDate, endDate, siteIdentifiers]);

  const chartDefinitions = isWidescreen ? CHART_DEFINITIONS :
    CHART_DEFINITIONS[0].concat(CHART_DEFINITIONS[1]).map(def => [def]);

  return (
    <Column className={mastheadAndColumnSpacing}>
      {
        modelRunId ? (<div className={`app-chatbot-container${ isChatVisible ? '' : ' hidden'}`}>
          <LLMChatBot modelRunId={modelRunId} />
        </div>) : null
      }
      <DataFilter
        isWidescreen={isWidescreen}
        endDate={endDate || dateRange.maxDate}
        filterValue={siteIdentifiers || [] as string[]}
        isChatVisible={isChatVisible}
        maxDate={dateRange.maxDate}
        minDate={dateRange.minDate}
        setEndDateValue={setEndDateValue}
        setFilter={setSites}
        setIsChatVisible={setIsChatVisible}
        setShowDataLabels={setShowDataLabels}
        setStartDateValue={setStartDateValue}
        showDataLabels={showDataLabels}
        siteData={allSitesList}
        startDate={startDate || dateRange.minDate}
      />
      {
        chartDefinitions.map((row, ix) => 
          (<Row key={`chart-row-${ix}`} width="fill">
            {
              row.map((chart) => (
                <div key={`chart-container-${chart.key}`} className={cardStyle}>
                  <Card minWidth={CARD_WIDTH}>
                    <CardHeader>
                      <Heading level={2} type="h500">
                        {chart.title}
                      </Heading>
                    </CardHeader>
                    <div className={chartContainerStyle}>
                      <ApiChart
                        loading={!extractedMetricValues || !extractedMetricValues[chart.key] || !extractedMetricValues[chart.key].values || extractedMetricValues[chart.key].values.length <= 0}
                        chartKey={chart.key}
                        data={extractedMetricValues[chart.key].values}
                        theme={theme}
                        xAxisKey="date"
                        yAxes={chart.series.map(({key, title}) => ({
                          title,
                          key: `${title}-${ix}-${Math.round(Math.random()*100)}`,
                          dataKey: title,
                          color: key === NEW ? theme.ChartNewSeries :
                            key === REPEAT ? theme.ChartRepeatSeries :
                            theme.ChartAggregateSeries
                        }))}
                        showDataLabels={showDataLabels}
                        showLegend={true}
                      />
                    </div>
                  </Card>
                </div>
              ))
            }
          </Row>)
        )
      }
    </Column>
  );
};

export const getForecastMetricValues = async (
  businessId: string, 
  country: string, 
  forecastId: string, 
  versionId: number, 
  startDate: string,
  endDate: string,
  metric: string
): Promise<GetForecastMetricResponse | void> => {
  return await getForecastMetric({
    businessId,
    country,
    forecastId,
    versionId,
    startDate,
    endDate,
    metric,
    granularity: 'monthly',
    siteIdentifiers: undefined,
  })
};

const extractData = (
  metricForecast: GetForecastMetricResponse | undefined,
  metric: string,
  startDate: string,
  endDate: string,
  siteIdentifiers: string[] | void
): ExtractedData => {

  const extractedData = {
    values: [] as ApiChartRecord,
    minDate: '',
    maxDate: '',
  } as ExtractedData;

  if (!metricForecast || !(metricForecast!.sites)) { return extractedData; }
  const aggregatedData: {
    [date: string]: {
      [metricType: string]: number;
    }
  } = {};

  const startDateMonth = startDate.substring(0, 7);
  const endDateMonth = endDate.substring(0, 7);
  const allowedSites = new Set(siteIdentifiers || Object.keys(metricForecast.sites));
  Object.entries(metricForecast.sites).forEach(([siteId, siteData]) => {
    if (!allowedSites.has(siteId)) { return; }
    const {values} = siteData;
    Object.keys(values).forEach((dataMetric: string) => {
      if (dataMetric !== metric) { return; }
      Object.entries(values[metric]).forEach(([metricType, metricValues]) => {
        metricValues.map((forecastValue: ForecastValue) => {
          const date = getLocalizedDateTimeStrings(convertDateTicks(forecastValue.date)).date.substring(0, 7);
          if (!extractedData.minDate || date < extractedData.minDate) { extractedData.minDate = date; }
          if (!extractedData.maxDate || date > extractedData.maxDate) { extractedData.maxDate = date; } // TODO: should be last day of month
          if (startDateMonth && date < startDateMonth) { return null; }
          if (endDateMonth && date > endDateMonth) { return null; }
          return {
            date: date,
            value: forecastValue.value,
          };
        }).filter(Boolean).forEach((formattedValue) => {
          const dateAggregatedData = aggregatedData[formattedValue!.date] = aggregatedData[formattedValue!.date] || {};
          dateAggregatedData[metricType] = (dateAggregatedData[metricType] || 0) + formattedValue!.value;
        });
      });
    });
  });

  extractedData.values = Object.keys(aggregatedData).map((date: string) => {
    const dateSeries = {date} as ApiChartRecord;
    Object.keys(aggregatedData[date]).forEach((metricType: string) => {
      dateSeries[capitalCase(metricType)] = Math.round(aggregatedData[date][metricType] * DECIMAL_PRECISION) / DECIMAL_PRECISION;
    });
    return dateSeries;
  });

  // Add day component to min/max for use in filter
  extractedData.minDate = `${extractedData.minDate.substring(0, 7)}-01`;
  extractedData.maxDate = `${extractedData.maxDate.substring(0, 7)}-01`;

  return extractedData;
}

export default (props: IPageProps) => (
  <ToplinePage title="Summary" {...props}>
    <ForecastSummaryPage {...props} />
  </ToplinePage>
);
