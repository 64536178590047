import React, {useEffect, useState} from 'react';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {ICellTextProps} from 'ka-table/props';
import {
  hideLoading,
  loadData,
  setSingleAction,
  showLoading,
  updateData,
  updatePageIndex,
  updatePagesCount,
} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {DispatchFunc} from 'ka-table/types';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';

import {getForecastOverlayList} from '../../../common/apis/forecasting-api-client';
import {GetForecastOverlayListResponse, ForecastOverlayListSortKeys} from '../../../common/apis/models/getForecastOverlayListResponse';
import {getTableStyles} from './OverlayListTableStyles';
import {useThemeContext} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';
import PageLoading from '../../../common/components/PageLoading';
import {DateCell} from '../../../common/components/ApiDataTable/ApiDataTableCells';

const DEFAULT_PAGE_SIZE = 50;
const ALLOWED_PAGE_SIZES = [50, 100, 200];

const getColumnTypeMapping = (
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'date':
      return <DateCell theme={theme} isDateOnly={true} multiLine={false} convertToUtc={true} {...props} />;
    case 'updatedAt':
      return <DateCell theme={theme} isDateOnly={false} multiLine={false} {...props} />;
  }
};

export const buildTable = (
  metricTitle: string,
  overlayList: GetForecastOverlayListResponse | void,
  isNetworkLevel: boolean,
): ITableProps => {
  const columns: Column[] = [
    isNetworkLevel ?
      {key: 'date', title: 'Date', dataType: DataType.Number, width: 30, sortDirection: SortDirection.Ascend} :
      {key: 'site', title: 'Site / FC', dataType: DataType.String, width: 30, sortDirection: SortDirection.Ascend},
    {key: 'value', title: metricTitle, dataType: DataType.Number, width: 30},
    {key: 'updatedAt', title: 'Last Updated', dataType: DataType.String, width: 40},
    {key: 'updatedBy', title: 'User', dataType: DataType.String, width: 50},
  ];

  const tablePropsInit: ITableProps = {
    columns,
    data: ((overlayList && overlayList.overlays) || []),
    rowKeyField: isNetworkLevel ? 'date' : 'site',
    sortingMode: SortingMode.SingleRemote,
    singleAction: loadData(),
    loading: {enabled: true},
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: ALLOWED_PAGE_SIZES,
      position: PagingPosition.Bottom,
    },
  };

  return tablePropsInit;
};

interface IProps {
  businessId: string;
  country: string;
  forecastId: string;
  isNetworkLevel: boolean;
  overlayList: GetForecastOverlayListResponse | void;
  overlayMetric: string;
  overlayMetricTitle: string;
  siteIdentifiers: string[] | undefined;
  versionId: number;
}

const OverlayListTable = ({
  businessId,
  country,
  forecastId,
  isNetworkLevel,
  overlayList,
  overlayMetric,
  overlayMetricTitle,
  siteIdentifiers,
  versionId
}: IProps) => {
  const {theme} = useThemeContext();
  const [tableProps, changeTableProps] = useState(buildTable(overlayMetricTitle, overlayList, isNetworkLevel));

  const dispatch: DispatchFunc = async (action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.UpdateSortDirection) {
      dispatch(updatePageIndex(0));
    } else if (action.type === ActionType.UpdatePageIndex || action.type === ActionType.UpdatePageSize) {
      dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.LoadData) {
      dispatch(showLoading());
      const sortCol = tableProps.columns.find((c) => c.sortDirection);
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;
      const currentPageIndex = tableProps?.paging?.pageIndex || 0;
      const offset = currentPageIndex > 0 ? pageSize * currentPageIndex : undefined;
      const result = await getForecastOverlayList({
        businessId,
        country,
        forecastId,
        versionId,
        overlayMetric,
        siteIdentifiers,
        offset,
        pageSize,
        sortKey: sortCol ? sortCol.key : (isNetworkLevel? ForecastOverlayListSortKeys.Date : ForecastOverlayListSortKeys.Site),
        sortDirection: sortCol ? sortCol.sortDirection : SortDirection.Ascend,
      });
      dispatch(updatePagesCount(result ? Math.ceil(result.totalRecords / pageSize) : 0));
      dispatch(updateData(result?.overlays || []));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    changeTableProps(buildTable(overlayMetricTitle, overlayList, isNetworkLevel));
  }, [overlayList, overlayMetric, overlayMetricTitle, changeTableProps, businessId, country, forecastId, isNetworkLevel, siteIdentifiers, versionId]);

  if (overlayList) {
    const {tableStyle} = getTableStyles(theme);
    return (
      <div className={tableStyle}>
        <Table
          {...tableProps}
          dispatch={dispatch}
          childComponents={{
            noDataRow: {
              content: () => 'No Data Found',
            },
            cellText: {
              content: getColumnTypeMapping.bind(getColumnTypeMapping, theme),
            },
          }}
        />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default OverlayListTable;
