import React from 'react';
import Select, {SelectOption} from '@amzn/meridian/select';

interface IProps {
  value: string | undefined;
  countries: Array<string>;
  visible: boolean;
  onSelect: (country: string) => void;
}

const CountrySelector = ({countries, onSelect, value, visible}: IProps) => {
  return (
    <>
      {visible ? (
        <Select value={value} onChange={onSelect} width={300} label="Country" popoverMaxHeight={250}>
          {countries.map((choice: string, ix: number) => (
            <SelectOption label={choice} value={choice} key={`select-country-${ix}`} />
          ))}
        </Select>
      ) : null}
    </>
  );
};

export default CountrySelector;
