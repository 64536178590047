import omit from 'lodash.omit';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {handleError} from './handleError';

export interface DownloadForecastOverlayParams {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
  overlayMetric: string;
  contentType: string | undefined;
}

export const downloadForecastOverlayEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: DownloadForecastOverlayParams
): Promise<string | void> => {
  try {
    const {businessId, country, forecastId, overlayMetric} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessId', 'country', 'forecastId', 'overlayMetric']),
    });
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId, overlayMetric},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/overlay/{overlayMetric}/download',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
