import React from 'react';
import {css} from 'emotion';
import Row from '@amzn/meridian/row';
import TypeaheadSelect from '../TypeaheadSelect';

export interface SiteData {
  siteIdentifiers: Set<string>,
  siteNames: Set<string>,
  siteMapping: {[site: string]: Set<string>},
};

interface IProps {
  isWidescreen: boolean,
  filterValue: string[],
  setFilter: React.Dispatch<React.SetStateAction<string[] | undefined>>,
  siteData: SiteData,
};

const formatSiteIdLabel = (values: any[]): string => {
  if (values.length < 8) {
    return values.map(v => v.label).join(', ');
  } else if (values.length > 0) {
    return `${values.length} sites selected`
  } else {
    return 'Search sites...'
  }
}

const formatSiteNameLabel = (values: any[]): string => {
  if (values.length < 4) {
    return values.map(v => v.label).join(', ');
  } else if (values.length > 0) {
    return `${values.length} cities selected`
  } else {
    return 'Search cities...'
  }
}

const smallMargin = css`
  margin-bottom: 4px !important;
`;

const SiteFilter = ({filterValue, isWidescreen, setFilter, siteData}: IProps) => {
  const siteIdentifiers = Array.from(siteData.siteIdentifiers).sort().map((val: string) => ({label: val, value: val}));
  const siteNames = Array.from(siteData.siteNames).sort().map((val: string) => ({label: val, value: val}));

  const setIdValue = (value: string | string[] | undefined) => {
    const valueArray: string[] = Array.isArray(value) ? value : value ? [value] : [];
    setFilter(valueArray);
  };
  const setNameValue = (value: string | string[] | undefined) => {
    const valueArray: string[] = Array.isArray(value) ? value : value ? [value] : [];
    setFilter(getSiteIds(valueArray, siteData));
  };

  const siteSearch = (
    <TypeaheadSelect
      width={500}
      label="Site / FC"
      placeholder="Search"
      options={siteIdentifiers}
      value={filterValue}
      setValue={setIdValue}
      labelFormatter={formatSiteIdLabel}
    />);

  const citySearch = (
    <TypeaheadSelect
      width={500}
      label="City"
      placeholder="Search"
      options={siteNames}
      value={getSiteNames(filterValue, siteData)}
      setValue={setNameValue}
      labelFormatter={formatSiteNameLabel}
    />
  );

  return isWidescreen ? (
    <Row className={smallMargin}>
      {siteSearch}
      {citySearch}
    </Row>
  ) : (
    <>
      <Row className={smallMargin}>
        {siteSearch}
      </Row>
      <Row className={smallMargin}>
        {citySearch}
      </Row>
    </>
  )
};

function getSiteIds(siteNames: string[], siteData: SiteData): string[] {
  const siteIds = new Set<string>();
  siteNames.forEach(siteName => {
    siteData.siteMapping[siteName].forEach(siteId => { siteIds.add(siteId); });
  });
  return Array.from<string>(siteIds);
}

function getSiteNames(siteIds: string[], siteData: SiteData): string[] {
  const siteNames = new Set<string>();
  siteData.siteNames.forEach(siteName => {
    siteIds.forEach(siteId => {
      if (siteData.siteMapping[siteName].has(siteId)) { siteNames.add(siteName); }
    });
  });
  return Array.from<string>(siteNames);
}

export default SiteFilter;
