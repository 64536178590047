import React, {useEffect, useState} from 'react';
import {useAuthorizationContext, useBusinessRegionContext, useForecastVersionContext} from './hooks';
import {GetForecastMetadataResponse} from '../common/apis/models/getForecastResponse';
import {IN_PROGRESS, SUCCESS} from '../common/constants/forecastStatus';
import {CLONED, FINAL} from '../common/constants/forecastType';
import { getForecastMetadata } from '../common/apis/forecasting-api-client';

export interface ForecastState {
  canApprove: boolean;
  canClone: boolean;
  canDiscard: boolean;
  canDownload: boolean;
  canOverlay: boolean;
  canRegenerate: boolean;
  ownedByCurrentUser: boolean;
  unprocessedOverlayMetrics: string[];
};

export interface ForecastContextType {
  state: ForecastStateContext,
  addForecastOverlayVersion: (metric: string, versionId: number) => void;
  clearUnprocessedOverlayMetrics: () => void;
};

export interface ForecastStateContext {
  forecast: GetForecastMetadataResponse | null;
  forecastState: ForecastState;
  forecastId: string | null;
  versionId: number | null;
  businessId: string | null;
  country: string | null;
};

const defaultState: ForecastStateContext = {
  forecast: null,
  forecastState: {
    canApprove: false,
    canClone: false,
    canDiscard: false,
    canDownload: false,
    canOverlay: false,
    canRegenerate: false,
    ownedByCurrentUser: false,
    unprocessedOverlayMetrics: [],
  },
  forecastId: null,
  versionId: null,
  businessId: null,
  country: null,
};

const getUnprocessedOverlayMetrics = (forecast: GetForecastMetadataResponse | null | void): string[] => {
  if (!forecast) { return []; }

  // We only care if we are looking at the most recent forecast version
  if (forecast.versions?.forecast[0]?.versionId !== forecast.versionId) {
    return [];
  }

  // Check overlays for all metrics and look for version numbers greater than the current forecast version
  return Object.entries(forecast.versions?.overlay)
    .filter(([, versions]) => versions[0]?.versionId > forecast.versionId)
    .map(([metric]) => metric);
};

export const determineForecastState = (
  forecast: GetForecastMetadataResponse | null | void,
  currentUser: string,
  isAdministrator: boolean,
  isApprover: boolean
): ForecastState => {
  const forecastState = {...defaultState.forecastState};

  if (forecast) {
    const unprocessedOverlayMetrics = getUnprocessedOverlayMetrics(forecast);
    forecastState.ownedByCurrentUser = currentUser.toLowerCase().startsWith(forecast.updatedBy.toLowerCase());
    const canWriteForecast = (forecastState.ownedByCurrentUser || isAdministrator || isApprover);

    forecastState.canDownload = Boolean(forecast.status === SUCCESS);
    forecastState.canApprove = Boolean(isApprover && forecastState.canDownload && forecast.type !== FINAL);
    forecastState.canOverlay = Boolean(canWriteForecast && forecastState.canDownload && forecast.type !== FINAL);
    forecastState.canClone = Boolean(forecastState.canDownload && forecast.type !== FINAL);
    forecastState.canDiscard = Boolean(forecast.status !== IN_PROGRESS && forecast.type === CLONED && canWriteForecast);
    forecastState.canRegenerate = Boolean(canWriteForecast && unprocessedOverlayMetrics.length);
    forecastState.unprocessedOverlayMetrics = unprocessedOverlayMetrics;
  }

  return forecastState;
};

export const ForecastContext = React.createContext<ForecastContextType>({
  state: defaultState,
  addForecastOverlayVersion: () => {},
  clearUnprocessedOverlayMetrics: () => {},
});

const ForecastProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
  const {user: currentUser, isAdministrator, isApprover} = useAuthorizationContext();
  const {business: businessId, country} = useBusinessRegionContext();
  const {forecastId, versionId} = useForecastVersionContext();
  const [state, setState] = useState(defaultState);

  const addOverlayVersion = (metric: string, versionId: number): void => {
    const newState = {...state};
    const metricVersions = newState.forecast?.versions.overlay[metric];
    if (metricVersions) {
      if (metricVersions.length && metricVersions[0].versionId === versionId) {
        metricVersions[0].updatedAt = new Date().getTime();
      } else {
        metricVersions.unshift({
          updatedAt: new Date().getTime(),
          versionId,
        });
      }
    }

    const unprocessedMetrics = new Set(newState.forecastState.unprocessedOverlayMetrics);
    unprocessedMetrics.add(metric);
    newState.forecastState.unprocessedOverlayMetrics = Array.from(unprocessedMetrics);
    newState.forecastState.canRegenerate = true;
    setState(newState);
  };

  const clearOverlayMetrics = () => {
    setState({
      ...state,
      forecastState: {
        ...(state.forecastState),
        unprocessedOverlayMetrics: [],
      }
    });
  };

  useEffect(() => {
    if (state.forecast?.forecastId && (state.forecast?.forecastId !== forecastId || state.forecast?.versionId !== versionId)) {
      setState({
        ...defaultState,
        forecastId, versionId, businessId, country
      });
    }
  }, [businessId, country, forecastId, versionId, state.forecast]);

  useEffect(() => {
    const loadForecast = async () => {
      if (!businessId || !country || !forecastId || !versionId) {
        return;
      }
      const forecast = await getForecastMetadata({
        businessId,
        country,
        forecastId,
        versionId,
        siteIdentifiers: undefined,
        metrics: undefined,
      });
      setState({
        forecast: forecast || null,
        forecastState: determineForecastState(forecast, currentUser, isAdministrator, isApprover),
        forecastId: forecast?.forecastId || null,
        versionId: forecast?.versionId || null,
        businessId: forecast?.businessId || null,
        country: forecast?.country || null,
      });
    };
    loadForecast();
  }, [businessId, country, currentUser, forecastId, isAdministrator, isApprover, versionId]);

  return (
    <ForecastContext.Provider value={{
      state,
      addForecastOverlayVersion: addOverlayVersion,
      clearUnprocessedOverlayMetrics: clearOverlayMetrics,
    }} >
      {children}
    </ForecastContext.Provider>
  );
};

export default ForecastProvider;
