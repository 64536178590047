// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {useContext} from 'react';
import {AuthenticationContext, AuthTokenPayload} from './AuthenticationProvider';
import {AuthorizationContext, AuthorizationContextType} from './AuthorizationProvider';
import {BusinessRegionContext, BusinessRegionContextType} from './BusinessRegionProvider';
import {FeatureFlagsContext, FeatureFlags} from './FeatureFlagsProvider';
import {ForecastContext, ForecastContextType} from './ForecastProvider';
import {ForecastVersionContext, ForecastVersionContextType} from './ForecastVersionProvider';
import {SettingsContext, SettingsContextType} from './SettingsProvider';
import {ThemeContext, ThemeContextType} from './ThemeProvider';

export const useAuthenticationContext: () => AuthTokenPayload = () => useContext(AuthenticationContext);
export const useAuthorizationContext: () => AuthorizationContextType = () => useContext(AuthorizationContext);
export const useBusinessRegionContext: () => BusinessRegionContextType = () => useContext(BusinessRegionContext);
export const useFeatureFlagsContext: () => FeatureFlags = () => useContext(FeatureFlagsContext);
export const useForecastContext: () => ForecastContextType = () => useContext(ForecastContext);
export const useForecastVersionContext: () => ForecastVersionContextType = () => useContext(ForecastVersionContext);
export const useSettingsContext: () => SettingsContextType = () => useContext(SettingsContext);
export const useThemeContext: () => ThemeContextType = () => useContext(ThemeContext);
