import {ForecastRecord} from '../../../common/apis/models/forecast';
import {formatForecastName, formatForecastStatus, formatFriendlyId, formatForecastDescription} from '../../../common/utils/forecast';
import {ERROR, IN_PROGRESS, SUCCESS} from '../../../common/constants/forecastStatus';
import {FINAL} from '../../../common/constants/forecastType';

export interface ForecastDetail {
  friendlyId: string;
  forecastId: string;
  versionId: number;
  isProcessing: boolean;
};

export interface ForecastDetailedStatus {
  status: string;
  type: string;
  formattedStatus: string;
  isError: boolean;
  isFinal: boolean;
  isProcessing: boolean;
};

export interface ForecastDetailsRow {
  forecastName: string;
  forecastDetail: ForecastDetail;
  [key: string]: string | number | object | null;
};

const formatForecastData = (data: ForecastRecord[]): ForecastDetailsRow[] => {
  return data.map((vals: ForecastRecord) => {
    const statusUpper = vals.status.toUpperCase();
    const typeUpper = vals.type.toUpperCase();
    const isProcessing = statusUpper === IN_PROGRESS;
    vals.description = formatForecastDescription(vals);
    return {
      ...vals,
      forecastName: formatForecastName(vals),
      forecastDetail: {
        friendlyId: formatFriendlyId(vals),
        forecastId: vals.forecastId,
        versionId: vals.versionId,
        description: vals.description,
        isProcessing: isProcessing,
      },
      forecastDetailedStatus: {
        status: vals.status,
        type: vals.type,
        formattedStatus: formatForecastStatus(statusUpper, typeUpper),
        isError: statusUpper === ERROR,
        isFinal: statusUpper === SUCCESS && typeUpper === FINAL,
        isProcessing: isProcessing,
      },
    };
  });
};

export default formatForecastData;
