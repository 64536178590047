import React from 'react';
import AuthenticationProvider from './AuthenticationProvider';
import AuthorizationProvider from './AuthorizationProvider';
import BusinessRegionProvider from './BusinessRegionProvider';
import FeatureFlagsProvider from './FeatureFlagsProvider';
import ForecastProvider from './ForecastProvider';
import ForecastVersionProvider from './ForecastVersionProvider';
import SettingsProvider from './SettingsProvider';
import ThemeProvider from './ThemeProvider';

const CompositeProvider = ({children}: React.PropsWithChildren<Record<never, any>>) =>
  (
    <SettingsProvider>
      <FeatureFlagsProvider>
        <AuthenticationProvider>
          <ThemeProvider>
            <BusinessRegionProvider>
              <AuthorizationProvider>
                <ForecastVersionProvider>
                  <ForecastProvider>
                    {children}
                  </ForecastProvider>
                </ForecastVersionProvider>
              </AuthorizationProvider>
            </BusinessRegionProvider>
          </ThemeProvider>
        </AuthenticationProvider>
      </FeatureFlagsProvider>
    </SettingsProvider>
  );

export default CompositeProvider;
