import React, {useCallback, useState} from 'react';
import {ForecastVersionIdentifier} from '../common/apis/models/forecast';

export interface ForecastVersionState {
  forecastId: string | null;
  versionId: number | null;
};

export interface ForecastVersionContextType extends ForecastVersionState {
  setForecastVersionIdentifier: (forecastVersion: ForecastVersionIdentifier) => void;
  clearForecastVersionIdentifier: () => void;
};

const FORECAST_URL_REGEX = /\/forecasts\/(?<forecastId>[a-f0-9-]+)\/versions\/(?<versionId>\d+)\/?/ig;
const {
  groups: {
    forecastId = null as string | null,
    versionId = null as string | null,
  } = {}
} = (FORECAST_URL_REGEX.exec(window.location.href) || {}) as any;

const defaultState: ForecastVersionState = {
  forecastId,
  versionId: versionId ? Number(versionId) : null,
};

export const ForecastVersionContext = React.createContext<ForecastVersionContextType>({
  ...defaultState,
  setForecastVersionIdentifier: () => {},
  clearForecastVersionIdentifier: () => {},
});

const ForecastVersionProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
  const [state, setState] = useState(defaultState);

  const setForecastVersionIdentifier = (forecastVersion: ForecastVersionIdentifier): void => {
    setState({
      ...state,
      forecastId: forecastVersion.forecastId,
      versionId: forecastVersion.versionId,
    });
  };

  const clearForecastVersionIdentifier = useCallback(() => {
    setState({
      ...state,
      forecastId: null,
      versionId: null,
    });
  }, [setState, state]);

  return (
    <ForecastVersionContext.Provider value={{
      ...state,
      setForecastVersionIdentifier: setForecastVersionIdentifier,
      clearForecastVersionIdentifier: clearForecastVersionIdentifier,
    }} >
      {children}
    </ForecastVersionContext.Provider>
  );
};

export default ForecastVersionProvider;
