import React, {useEffect, useState} from 'react';
import PageLoading from '../common/components/PageLoading';

export interface SettingsContextType {
  settings: {
    [key: string]: string | number | boolean | object;
  }
};

const defaultState = {
  settingsLoaded: false,
  settings: {},
};

export const SettingsContext = React.createContext(defaultState);

const SettingsProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
    const [state, setState] = useState(defaultState);
    useEffect(() => {
        const loadSettings = async () => {
            const settings = (await (await fetch(`${window.location.origin}/settings.json`)).json()) as SettingsContextType;
            setState({
              settingsLoaded: true,
              settings,
            });
        };

        loadSettings();
    }, [state.settingsLoaded, setState]);


    if (state.settingsLoaded) {
        return <SettingsContext.Provider value={state}>{children}</SettingsContext.Provider>;
    }

    return <PageLoading />;
};

export default SettingsProvider;
