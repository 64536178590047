import React, {useCallback} from 'react';
import Icon from '@amzn/meridian/icon';
import Toggle from '@amzn/meridian/toggle';
import asleep from '@amzn/meridian-tokens/base/icon/asleep';
import {useThemeContext} from '../../../app/hooks';
import {ITheme} from '../../../common/styles/themes/models';

interface IProps {
  theme: ITheme;
};

const DarkToggleLink = ({theme}: IProps) => {
  const {setDarkMode} = useThemeContext();
  const toggleDarkMode = useCallback(() => {
    setDarkMode(!theme.isDarkMode);
  }, [setDarkMode, theme.isDarkMode]);
  return (
    <Toggle checked={theme.isDarkMode} onChange={toggleDarkMode}>
      <Icon tokens={asleep} />
    </Toggle>
  );
};

export default DarkToggleLink;
