import {GetForecastMetricResponse} from '../../apis/models/getForecastResponse';
import {SiteData} from './index';

export const extractForecastSites = (forecastMetric: GetForecastMetricResponse | null | undefined): SiteData => {
  const siteData = {
    siteIdentifiers: new Set<string>(),
    siteNames: new Set<string>(),
    siteMapping: {} as {[site: string]: Set<string>},
  };
  if (!forecastMetric || !forecastMetric.sites) {
    return siteData;
  }
  Object.entries(forecastMetric.sites).forEach(([_, site]) => {
    siteData.siteIdentifiers.add(site.identifier);
    siteData.siteNames.add(site.name);
    siteData.siteMapping[site.name] = siteData.siteMapping[site.name] || new Set<string>();
    siteData.siteMapping[site.name].add(site.identifier);
  });
  return siteData;
};
