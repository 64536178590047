import React, {useState} from 'react';
import Button from '@amzn/meridian/button';
import Loader from '@amzn/meridian/loader';
import {useHistory} from 'react-router-dom';
import {useBusinessRegionContext} from '../../app/hooks';

// This happens almost instantaneously (1 ms), but it looks funny if the
// loading spinner shows up faster than 300ms.
const AUTH_WAIT = 300;

interface IProps {
  business: string | undefined;
  country: string | undefined;
  enabled: boolean;
  region: number | undefined;
};

const EnterPortalButton = ({business, country, enabled, region}: IProps) => {
  let history = useHistory();
  const {setBusinessRegionSelection} = useBusinessRegionContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    if (!business || !country || !region) { return; }
    let userStorage = window.localStorage;

    userStorage.setItem('business', `${business}`);
    userStorage.setItem('region', `${region}`);
    userStorage.setItem('country', `${country}`);
    setBusinessRegionSelection(business, country, region);
    setIsLoading(true);

    // Wait for authorization store to update
    setTimeout(() => { history.push('/'); }, AUTH_WAIT);
  };

  return (
    <Button type="tertiary" size="large" disabled={!enabled || isLoading} onClick={handleClick}>
      Enter Portal
      {isLoading ? <Loader size="small" /> : null}
    </Button>
  );
};

export default EnterPortalButton;
