import {css} from 'emotion';
import {ITheme} from '../../styles/themes/models';
import 'ka-table/style.scss';

export const getBaseTableStyle = (theme: ITheme) => {
  return css`
    .ka {
      border-radius: 3px;
      box-shadow: 0px 2px 4px ${theme.TableShadow};
      margin-bottom: 20px;

      .ka-table-wrapper {
        max-height: 75.5vh;

        .ka-table {
          min-width: 1500px;
          overflow: auto;
        }

        .ka-thead-fixed {
        }

        .ka-thead-cell {
          z-index: 2;
          font-size: 16px;
          font-weight: bolder;
          color: ${theme.RowHeader};
          background-color: ${theme.RowHeaderBackground};
          border-bottom: 1px solid ${theme.RowHeaderBorder};
          border-right: 1px solid ${theme.RowHeaderBorder};
        }

        .ka-row {
          border: 0;
          .ka-cell .ka-cell-text {
            line-height: 16px;
            overflow: hidden;
          }
          &:nth-of-type(odd) .ka-cell {
            color: ${theme.TextPrimary};
            border-bottom: 1px solid ${theme.RowBorder};
            border-right: 1px solid ${theme.RowBorder};
            background-color: ${theme.RowPrimary};
          }
          &:nth-of-type(even) .ka-cell {
            color: ${theme.TextPrimary};
            border-bottom: 1px solid ${theme.RowAlternateBorder};
            border-right: 1px solid ${theme.RowAlternateBorder};
            background-color: ${theme.RowAlternate};
          }
          &:hover .ka-cell {
            color: ${theme.TextPrimary};
            border-bottom: 1px solid ${theme.RowHighlightBorder};
            border-right: 1px solid ${theme.RowHighlightBorder};
            background-color: ${theme.RowHighlight};
          }
        }
      }

      .ka-paging {
        background-color: ${theme.RowHeaderBackground};
        border-bottom: 1px solid ${theme.RowHeaderBorder};
        .ka-paging-size, .ka-paging-page-index {
          color: ${theme.TextPrimary};
          background-color: ${theme.RowPrimary};
        }
        .ka-paging-size-active, .ka-paging-page-index-active {
          background-color: ${theme.RowHighlight};
        }
      }
    }

    @media only screen and (max-width: 1500px) {
      .ka .ka-table-wrapper .ka-row .ka-thead-cell .ka-cell {
        padding: 8px;
      }
    }

    @media only screen and (max-width: 1020px) {
      .ka .ka-table-wrapper .ka-row .ka-thead-cell .ka-cell {
        padding: 8px 4px;
      }
    }

    @media only screen and (max-width: 800px) {
      .ka .ka-table-wrapper .ka-row .ka-thead-cell .ka-cell {
        padding: 8px 2px;
      }
    }
  `;
};

export const dateTimeCell = css`
  min-width: 80px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
