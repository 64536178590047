import React from 'react';
import {navItem, navSection} from './NavBarSide';
import {ForecastState} from '../../app/ForecastProvider';
import ApproveForecastLinkButton from '../actions/ApproveForecastLinkButton';
import CloneForecastLinkButton from '../actions/CloneForecastLinkButton';
import DiscardForecastLinkButton from '../actions/DiscardForecastLinkButton';
import DownloadForecastLinkButton from '../actions/DownloadForecastLinkButton';
import RegenerateForecastLinkButton from '../actions/RegenerateForecastLinkButton';
import {GetForecastMetadataResponse} from '../../common/apis/models/getForecastResponse';

const getSideNavigationDefinition = (
  forecast: GetForecastMetadataResponse | null,
  forecastPath: string,
  forecastState: ForecastState,
  permissionSet: Set<string>,
  isWidescreen: boolean,
  clearUnprocessedOverlayMetrics: () => void,
): navSection[] => {
  const navItems: navSection[] = [];

  if (permissionSet.has('canViewDashboard')) {
    navItems.push(
      {
        title: 'Dashboard',
        items: [],
        link: `/`,
      });
  }
  if (permissionSet.has('canViewSummary')) {
    navItems.push({
      title: isWidescreen ? 'Forecast Summary' : 'Summary',
      items: [],
      link: `${forecastPath}/summary`,
    });
    navItems.push({
      title: 'Metadata',
      items: [],
      link: `${forecastPath}/metadata`,
    });
  }
  if (permissionSet.has('canViewHistory')) {
    navItems.push({
      title: 'History',
      items: [],
      link: `${forecastPath}/history`,
    });
  }
  if (permissionSet.has('canOverlayForecast')) {
    navItems.push({
      title: isWidescreen ? 'View/Update Inputs (FC Level)' : 'View FC Inputs',
      items: [
        {
          title: 'Price Index',
          link: `${forecastPath}/price-index`,
        },
        {
          title: 'Discounts',
          link: `${forecastPath}/discounts`,
        }
      ]
    });
    navItems.push({
      title: isWidescreen ? 'View/Update Inputs (Network Level)' : 'View Network Inputs',
      items:[
        {
          title: 'UFT',
          link: `${forecastPath}/uft`,
        },
        {
          title: isWidescreen ? 'OOC - Same Day' : 'OOC (Same)',
          link: `${forecastPath}/oocsd`,
        },
        {
          title: isWidescreen ? 'OOC - Next Day' : 'OOC (Next)',
          link: `${forecastPath}/oocnd`,
        }
      ]
    });
  }
  if (permissionSet.has('canViewSummary')) {
    navItems.push({
      title: 'View Model Assumptions',
      items: [
        {
          title: 'Social Mobility Index',
          link: `${forecastPath}/covid-mobility`,
        }
      ]
    });
  }
  // if (permissionSet.has('canViewSummary')) {
  //   navItems.push({
  //     title: 'Traceability',
  //     items:[
  //       {
  //         title: 'FC Roll-up',
  //         link: `${forecastPath}/fc-rollup`
  //       },
  //       {
  //         title: 'City Roll-up',
  //         link: `${forecastPath}/city-rollup`
  //       },
  //       {
  //         title: 'Customers',
  //         link: `${forecastPath}/customers`
  //       }
  //     ]
  //   });
  // }

  const availableActions: navItem[] = [];
  if (forecast) {
    if (permissionSet.has('canViewSummary')) {
      availableActions.push({
        disabled: !forecastState.canDownload,
        title: isWidescreen ? 'Download to CSV' : 'Download',
        getActionButton: (anchorRef, open, setOpen) =>
          <DownloadForecastLinkButton anchorRef={anchorRef} forecast={forecast} open={open} setOpen={setOpen} />
      });
    }
    if (permissionSet.has('canCloneForecast')) {
      availableActions.push({
        disabled: !forecastState.canClone,
        title: 'Clone',
        getActionButton: (anchorRef, open, setOpen) =>
          <CloneForecastLinkButton anchorRef={anchorRef} forecast={forecast} open={open} setOpen={setOpen} />
      });
    }
    if (permissionSet.has('canRegenerateForecast')) {
      availableActions.push({
        disabled: !forecastState.canRegenerate,
        title: 'Regenerate',
        getActionButton: (anchorRef, open, setOpen) =>
          <RegenerateForecastLinkButton anchorRef={anchorRef} forecast={forecast} open={open} setOpen={setOpen}
            clearUnprocessedOverlayMetrics={clearUnprocessedOverlayMetrics}
          />
      });
    }
    if (permissionSet.has('canCloneForecast')) {
      availableActions.push({
        disabled: !forecastState.canDiscard,
        title: 'Discard',
        getActionButton: (anchorRef, open, setOpen) =>
          <DiscardForecastLinkButton anchorRef={anchorRef} forecast={forecast} open={open} setOpen={setOpen} />
      });
    }
    if (permissionSet.has('canFinalizeForecast')) {
      availableActions.push({
        disabled: !forecastState.canApprove,
        title: isWidescreen ? 'Approve & Finalize' : 'Finalize',
        getActionButton: (anchorRef, open, setOpen) =>
          <ApproveForecastLinkButton anchorRef={anchorRef} forecast={forecast} open={open} setOpen={setOpen} />
      });
    }
  }
  if (availableActions.length) {
    navItems.push(
      {
        title: 'Actions',
        items: availableActions,
      });
  }

  return navItems;
};

export default getSideNavigationDefinition;
