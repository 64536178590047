const loadingMessages = [
  "Adding Hidden Agendas",
  "Adjusting Bell Curves",
  "Aesthesizing Industrial Areas",
  "Aligning Covariance Matrices",
  "Applying Feng Shui Shaders",
  "Applying Theatre Soda Layer",
  "Asserting Packed Exemplars",
  "Attempting to Lock Back-Buffer",
  "Binding Sapling Root System",
  "Breeding Fauna",
  "Building Data Trees",
  "Bureacritizing Bureaucracies",
  "Calculating Inverse Probability Matrices",
  "Calculating Llama Expectoration Trajectory",
  "Calibrating Blue Skies",
  "Charging Ozone Layer",
  "Coalescing Cloud Formations",
  "Cohorting Exemplars",
  "Collecting Meteor Particles",
  "Compounding Inert Tessellations",
  "Compressing Fish Files",
  "Computing Optimal Bin Packing",
  "Concatenating Sub-Contractors",
  "Containing Existential Buffer",
  "Debarking Ark Ramp",
  "Debunching Unionized Commercial Services",
  "Deciding What Message to Display Next",
  "Decomposing Singular Values",
  "Decrementing Tectonic Plates",
  "Deleting Ferry Routes",
  "Depixelating Inner Mountain Surface Back Faces",
  "Depositing Slush Funds",
  "Destabilizing Economic Indicators",
  "Determining Width of Blast Fronts",
  "Deunionizing Bulldozers",
  "Dicing Models",
  "Diluting Livestock Nutrition Variables",
  "Downloading Satellite Terrain Data",
  "Exposing Flash Variables to Streak System",
  "Extracting Resources",
  "Factoring Pay Scale",
  "Fixing Election Outcome Matrix",
  "Flood-Filling Ground Water",
  "Flushing Pipe Network",
  "Gathering Particle Sources",
  "Generating Jobs",
  "Gesticulating Mimes",
  "Graphing Whale Migration",
  "Hiding Willio Webnet Mask",
  "Implementing Impeachment Routine",
  "Increasing Accuracy of RCI Simulators",
  "Increasing Magmafacation",
  "Initializing My Sim Tracking Mechanism",
  "Initializing Rhinoceros Breeding Timetable",
  "Initializing Robotic Click-Path AI",
  "Inserting Sublimated Messages",
  "Integrating Curves",
  "Integrating Illumination Form Factors",
  "Integrating Population Graphs",
  "Iterating Cellular Automata",
  "Lecturing Errant Subsystems",
  "Mixing Genetic Pool",
  "Modeling Object Components",
  "Mopping Occupant Leaks",
  "Normalizing Power",
  "Obfuscating Quigley Matrix",
  "Overconstraining Dirty Industry Calculations",
  "Partitioning City Grid Singularities",
  "Perturbing Matrices",
  "Pixalating Nude Patch",
  "Polishing Water Highlights",
  "Populating Lot Templates",
  "Preparing Sprites for Random Walks",
  "Prioritizing Landmarks",
  "Projecting Law Enforcement Pastry Intake",
  "Realigning Alternate Time Frames",
  "Reconfiguring User Mental Processes",
  "Relaxing Splines",
  "Removing Road Network Speed Bumps",
  "Removing Texture Gradients",
  "Removing Vehicle Avoidance Behavior",
  "Resolving GUID Conflict",
  "Reticulating Splines",
  "Retracting Phong Shader",
  "Retrieving from Back Store",
  "Reverse Engineering Image Consultant",
  "Routing Neural Network Infanstructure",
  "Scattering Rhino Food Sources",
  "Scrubbing Terrain",
  "Searching for Llamas",
  "Seeding Architecture Simulation Parameters",
  "Sequencing Particles",
  "Setting Advisor Moods",
  "Setting Inner Deity Indicators",
  "Setting Universal Physical Constants",
  "Sonically Enhancing Occupant-Free Timber",
  "Speculating Stock Market Indices",
  "Splatting Transforms",
  "Stratifying Ground Layers",
  "Sub-Sampling Water Data",
  "Synthesizing Gravity",
  "Synthesizing Wavelets",
  "Time-Compressing Simulator Clock",
  "Unable to Reveal Current Activity",
  "Weathering Buildings",
  "Zeroing Crime Network"
];

const answerMessages = [
  "Please let me analyze that data. I'll respond when finished.",
  "Hmm, let me find out for you.",
  "Good question. I'll take a look.",
  "I'll find out shortly.",
];

const errorMessages = [
  "Sorry, I'm having trouble understanding you.",
  "Oops! Something went wrong.",
  "My apologies, I couldn't process your request.",
  "I'm sorry, but I didn't catch that.",
  "There seems to be an error. Please try again later.",
  "I'm afraid I don't have the information you're looking for.",
  "Sorry, I'm not able to assist with that right now.",
  "An error occurred. Please try again."
];

const greetingMessages = [
  "Hello! How can I assist you with your data analysis?",
  "Hi there! How can I help you today with your data analysis tasks?",
  "Greetings! How can I support you with your data analysis needs?",
  "Welcome! How can I assist you in analyzing your data?",
  "Hey! How can I help you make sense of your data today?",
  "Good day! How can I support you in your data analysis endeavors?",
  "Hi! How can I assist you in uncovering insights from your data?"
];

const stillWorkingMessages = [
  "Just a moment, I'm still analyzing the data.",
  "Please bear with me, I'm processing your request.",
  "I'm still working on your previous question, it may take a moment.",
  "Give me a few seconds, I'm crunching the numbers for you.",
  "I'm still in the process of analyzing the data, please wait.",
  "The analysis is underway, please hold on.",
  "Analyzing your data, it shouldn't take too long.",
  "I'm processing your data, thank you for your patience."
];

function getRandomMessage(messages: Array<string>): string {
  return messages[Math.floor(Math.random() * messages.length)];
}

export function getLoadingMessage(): string {
  return `${getRandomMessage(loadingMessages)}...`;
};

export function getAnswerMessage(): string {
  return getRandomMessage(answerMessages);
};

export function getErrorMessage(): string {
  return getRandomMessage(errorMessages);
};

export function getGreetingMessage(): string {
  return getRandomMessage(greetingMessages);
};

export function getStillWorkingMessage(): string {
  return getRandomMessage(stillWorkingMessages);
};
