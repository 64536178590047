import React from 'react';
import ActionPopover from '../../common/components/ActionPopover';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {IActionButtonProps} from './models';
import ForecastTitle from './ForecastTitle';
import {getForecastDownload} from '../../common/apis/forecasting-api-client';

interface IProps {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
  shouldEnable: boolean;
}

const DownloadForecastLinkButton = ({anchorRef, forecast, open, setOpen}: IActionButtonProps) => {
  const handleForcastDownloadFormSubmit = async () => {
    const forecastDownloadUrl = (await getForecastDownload({
      businessId: forecast.businessId,
      country: forecast.country,
      forecastId: forecast.forecastId,
      versionId: forecast.versionId,
    }))!.downloadUrl;
    document.location.href = forecastDownloadUrl;
  };


  return (
    <ActionPopover
      actionButtonText="Yes"
      actionCallback={handleForcastDownloadFormSubmit}
      actionTitle="Download Forecast to CSV"
      anchorRef={anchorRef}
      cancelButtonText="No"
      open={open}
      setOpen={setOpen}
    >
      <ForecastTitle forecast={forecast} />
      <Row>
        <Text>Are you sure that you want to download this forecast?</Text>
      </Row>
    </ActionPopover>
  );
};

export default DownloadForecastLinkButton;
