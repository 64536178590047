import React, {useEffect, useRef, useState} from 'react';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {useBusinessRegionContext, useForecastVersionContext} from '../../app/hooks';
import BusinessSelector from '../../features/select-business-page/selectors/BusinessSelector';
import CountrySelector from '../../features/select-business-page/selectors/CountrySelector';
import EnterPortalButton from './EnterPortalButton';

const mastheadAndColumnSpacing = css`
  padding-top: 2%;
`;

const COUNTRY_REGION_MAP: {[country: string]: number} = {
  DE: 2,
  ES: 2,
  FR: 2,
  GB: 2,
  IT: 2,
  US: 1,
};

const SelectBusinessPage = () => {
  const forecastVersionContext = useRef(useForecastVersionContext());
  const businessRegionContext = useRef(useBusinessRegionContext());
  const [business, setBusiness] = useState<string | undefined>();
  const [country, setCountry] = useState<string | undefined>();
  const [region, setRegion] = useState<number | undefined>();
  const [countryVisible, setCountryVisible] = useState(false);
  const [countryChoices, setCountryChoices] = useState<Array<string>>([]);
  const [canEnterPortal, setCanEnterPortal] = useState(false);

  useEffect(() => {
    businessRegionContext.current.clearBusinessRegionSelection();
    forecastVersionContext.current.clearForecastVersionIdentifier();
  }, [businessRegionContext, forecastVersionContext]);

  const onSelectBusiness = (business: string) => {
    const selectedBusiness = business ? business.toUpperCase() : undefined;
    setBusiness(selectedBusiness);
    setCountry(undefined);
    setRegion(undefined);
    setCountryVisible(true);
    setCanEnterPortal(false);
    switch (selectedBusiness) {
      case 'AFS':
        setCountryChoices(['US']);
        break;
      case 'UFF':
        setCountryChoices(Object.keys(COUNTRY_REGION_MAP));
        break;
    }
  };
  const onSelectCountry = (country: string) => {
    setCountry(country);
    setRegion(COUNTRY_REGION_MAP[country]);
    setCanEnterPortal(!!country);
  };

  return (
    <Column alignmentHorizontal="center" className={mastheadAndColumnSpacing}>
      <Column alignmentHorizontal="center" className={mastheadAndColumnSpacing} spacing="large">
        <BusinessSelector value={business} onSelect={onSelectBusiness} />
        <CountrySelector visible={countryVisible} value={country} countries={countryChoices} onSelect={onSelectCountry} />
        <EnterPortalButton business={business} country={country} enabled={canEnterPortal} region={region} />
      </Column>
    </Column>
  );
};

export default SelectBusinessPage;
