import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {GetForecastMetricResponse} from '../models/getForecastResponse';
import {handleError} from './handleError';

export interface GetForecastMetricParams {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
  metric: string;
  startDate: string;
  endDate: string; 
  granularity: string;
  siteIdentifiers: string[] | undefined;
}

export const getForecastMetricEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: GetForecastMetricParams
): Promise<GetForecastMetricResponse | void> => {
  try {
    const {businessId, country, forecastId, versionId, metric, startDate, endDate, granularity, siteIdentifiers} = params;
    const queryParams = { 
      versionId: versionId.toString(),
      granularity: granularity,
      startTime: startDate,
      endTime: endDate,
    } as { [key: string]: string };
    // TODO add startTime and endTime to queryParams to improve API response performance
    if (siteIdentifiers && siteIdentifiers.length) {
      queryParams.siteIdentifiers = siteIdentifiers.join(',');
    }
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId, metric},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/metric/{metric}',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
