import {add, format} from 'date-fns';
import {OP1, OP2, Q1G, Q2G, Q3G, Q4G, YP3, YP10} from '../constants/forecastPeriod';

const DATE_FORMAT_STRING = 'yyyy-MM-dd';

export const MILLIS_IN_DAY = 24 * 60 * 60 * 1000;
export const MILLIS_IN_SECOND = 1000;
export const MILLIS_IN_THIRTY_DAYS = 30 * MILLIS_IN_DAY;

export const convertDateTicks = (value: number): Date =>
  (convertToUTC(new Date(value * MILLIS_IN_SECOND)));

export const convertTimestampTicks = (value: number): Date =>
  (new Date(value * MILLIS_IN_SECOND));

export const getISODate = (value: Date): string => {
  return value.toISOString().substring(0, 10);
};

export const getLocalizedDateTimeString = (value: Date): string => {
  const {date, time} = getLocalizedDateTimeStrings(value);
  return `${date} ${time}`;
};

export const getLocalizedDateTimeStrings = (value: Date): {date: string; time: string} => {
  return {
    date: format(value, DATE_FORMAT_STRING),
    time: value.toLocaleTimeString(),
  };
};

export const getLocalizedAPIsStartDate = (value: Date): string => {
  return new Date(value.setDate(value.getDate() - value.getDay())).toLocaleDateString('en-US');
};

export const formatPeriodDate = (period: string, periodStart: number): string => {
  const startDate = convertDateTicks(periodStart);
  return `${period}-${format(startDate, 'yyyy')}`;
}

const getPeriodLengthInMonths = (period: string): number => {
  switch (period) {
    case YP3: return 36;
    case YP10: return 120;
    case OP1:
    case OP2:
    case Q1G:
    case Q2G:
    case Q3G:
    case Q4G:
    default: return 18;
  }
};

export const convertToUTC = (value: Date): Date => {
  return add(value, {minutes: value.getTimezoneOffset()});
}

export const getPeriodEndDate = (startDate: Date, period: string): Date => {
  const periodLength = getPeriodLengthInMonths(period);
  return add(startDate, {months: periodLength, days: -1});
};
