import React from 'react';
import getLLMAPI from './LLMAPI';
import {getLoadingMessage, getAnswerMessage, getGreetingMessage, getStillWorkingMessage} from './messages';

const getActionProvider = (modelRunId) => {
  const staticState = {
    working: false,
    interruptions: 0,
  };
  const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const llmApi = getLLMAPI(modelRunId);
  
    const handleHello = () => {
      const botMessage = createChatBotMessage(getGreetingMessage());
      
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    };
  
    const handleMessage = async ({message}) => {
      if (staticState.working) {
        // Stop processing and respond to the interruption.
        // Clear the interruption and the response later
        staticState.interruptions += 2;
        return void setState((prev) => ({
          ...prev,
          messages: [...prev.messages, createChatBotMessage(getStillWorkingMessage())]
        }));
      }

      staticState.working = true;
      staticState.interruptions = 0;

      const getLLMResponsePromise = llmApi.askQuestion(message);
  
      const delayMessage = createChatBotMessage(getAnswerMessage());
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, delayMessage]
      }));
  
      // Create loading messages 6 seconds apart that we will clear later
      const addMessagesInterval = setInterval(() => {
        if (!staticState.working) { return; }
        staticState.interruptions += 1;
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, createChatBotMessage(getLoadingMessage())]
        }));
      }, 6000);
  
      const answerText = await getLLMResponsePromise;
      const botMessage = createChatBotMessage(answerText);
    
      console.log(staticState.interruptions);
      setState((prev) => ({
        ...prev,
        // Erase the working messages before populating the answer
        messages: [...(prev.messages.slice(0, prev.messages.length - staticState.interruptions - 1)), botMessage]
      }));

      staticState.working = false;
      clearInterval(addMessagesInterval);
    };
    
    return (
      <div>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            actions: {
              handleHello,
              handleMessage
            },
          });
        })}
      </div>
    );
  };
  return ActionProvider;
};

export default getActionProvider;