// Forecast Metrics
export const CUSTOMERS = 'customers';
export const OPS = 'ops';
export const ORDERS = 'orders';
export const UNITS = 'units';

// Overlay Metrics
export const COVID_MOBILITY = 'covid_mobility';
export const DISCOUNTS = 'discounts';
export const PRICE_INDEX = 'price_index';
export const OOC_ND = 'ooc_nd';
export const OOC_SD = 'ooc_sd';
export const UFT = 'uft';

// Metric Types
export const AGGREGATE = 'aggregate';
export const NEW = 'new';
export const REPEAT = 'repeat';

export const metricNames = {
  [CUSTOMERS]: 'Customers',
  [OPS]: 'Order Product Sales',
  [ORDERS]: 'Orders',
  [UNITS]: 'Units',
  [COVID_MOBILITY]: 'COVID Mobility',
  [DISCOUNTS]: 'Discounts',
  [PRICE_INDEX]: 'Price Index',
  [OOC_ND]: 'OOC (Next Day)',
  [OOC_SD]: 'OOC (Same Day)',
  [UFT]: 'UFT',
  [AGGREGATE]: 'Aggregate',
  [NEW]: 'New',
  [REPEAT]: 'Repeat',
} as {[metric: string]: string};
