import React from 'react';
import Chatbot from 'react-chatbot-kit';
import config from './config';
import MessageParser from './MessageParser';
import getActionProvider from './getActionProvider';
import 'react-chatbot-kit/build/main.css';

interface IProps {
  modelRunId?: string | null;
};

const LLMChatBot = ({
  modelRunId,
}: React.PropsWithChildren<IProps>) => {
  return (
    <div>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={getActionProvider(modelRunId)}
        validator={(message: string) => Boolean(message && message.length)}
      />
    </div>
  );
};

export default LLMChatBot;
