import React, {useRef, useState} from 'react';
import {css} from 'emotion';
import {CSVLink} from 'react-csv'
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import download from "@amzn/meridian-tokens/base/icon/download-small";
import {downloadForecastOverlay} from '../../../common/apis/forecasting-api-client';

interface IProps {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
  overlayMetric: string;
  shouldEnable: boolean;
}

const downloadButtonStyle = css`
  padding-left: 0;
`;

const downloadButtonTextStyle = css`
  margin-left: 6px !important;
`;

const DownloadTemplateButton = ({businessId, country, forecastId, versionId, overlayMetric, shouldEnable}: IProps) => {
  const [overlayMetricData, setOverlayMetricData] = useState('');
  const csvLink = useRef(null);
  
  const onClickHandler = async () => {
    downloadForecastOverlay({
        businessId,
        country,
        forecastId,
        versionId,
        overlayMetric,
        contentType: undefined,
    })
    .then((response) => {
      setOverlayMetricData(response!);

      // @ts-ignore
      csvLink.current.link.click();
    })
    .catch((err) => console.log(err));
  };
  return (
    <div>
      <Button className={downloadButtonStyle} type="tertiary" size="medium" minWidth="270px" onClick={onClickHandler} disabled={!shouldEnable}>
        <Icon tokens={download} />
        <span className={downloadButtonTextStyle}>Download Template / Data</span>
      </Button>
      <CSVLink
        data={overlayMetricData}
        filename={`${overlayMetric}.csv`}
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </div>
  );
};

export default DownloadTemplateButton;
